import { format, isValid } from "date-fns";
import {
  defaultAddress,
  defaultProfilePics,
  followUpConsultationIdError,
  followUpDoctorIdError,
  followUpErrorObj,
  hospitalSteps,
  steps,
} from "./mockData";

export const checkAddress = (addressObj) => {
  try {
    const index = Object.keys(addressObj || {}).findIndex(
      (key) => !addressObj[key] || addressObj[key] === ""
    );
    if (index === -1) return true;
    return false;
  } catch (error) {
    console.error("Error from checkAddress func.:", error);
    return false;
  }
};
function extractKeyAndIdFromURL(url) {
  const params = new URLSearchParams(url.split("?")[1]);
  const key = params.get("key");

  if (key == null) {
    return { key: false };
  } else {
    return { key };
  }
}

const url = window.location.href;
const { key } = extractKeyAndIdFromURL(url);

export const getStyles = (widgetColor = "#3e5ea9") => ({
  bodyBG: {
    padding: "8px",
    borderRadius: "24px",
    display: "flex",
    overflow: "hidden",
    position: "relative",
    marginTop: key ? "-45%" : "",
  },
  before: {
    content: "",
    position: "absolute",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    padding: "43px",
    borderRadius: "24px",
    backgroundColor: key ? "none" : `${widgetColor}`,
    opacity: 0.2,
  },
  children: {
    zIndex: 1,
    opacity: 1,
  },
});

export const validateFollowFields = (values) => {
  const { isFollowUp, followUpConsultationId, doctor } = values;
  if (!isFollowUp) {
    return {
      isValidated: false,
      error: followUpErrorObj,
    };
  }

  if (!followUpConsultationId) {
    return {
      isValidated: false,
      error: followUpConsultationIdError,
    };
  }

  if (!doctor) {
    return {
      isValidated: false,
      error: followUpDoctorIdError,
    };
  }

  return {
    isValidated: true,
    error: null,
  };
};

export const validateValues = (
  hasPharmacyFeature,
  values,
  pharmacies = [],
  isFollowUp = false
) => {
  try {
    if (isFollowUp) {
      const result = validateFollowFields(values);
      if (!result?.isValidated) return result;
    }

    if (!hasPharmacyFeature) return { isValidated: true, error: null };
    if (pharmacies?.length < 1) return { isValidated: true, error: null };

    if (
      !values?.pharmacyCode ||
      values?.pharmacyCode === "" ||
      values?.pharmacyCode === "Select a pharmacy"
    ) {
      return {
        isValidated: false,
        error: { name: "pharmacyCode", message: "Please select a pharmacy." },
      };
    }

    if (typeof values?.isDelivery !== "boolean") {
      return {
        isValidated: false,
        error: {
          name: "isDelivery",
          message: "Please select a delivery option.",
        },
      };
    }

    return { isValidated: true, error: null };
  } catch (error) {
    console.error("error from validateValues func:", error);
    return {
      isValidated: false,
      error: {
        name: "none",
        message: "Something went wrong while trying to validate this form.",
      },
    };
  }
};

export const formatValues = (values, isPharmacy, isHospitalConsultation) => {
  const isFollowUp = values?.isFollowUp;
  const isDependant = values?.principalHmoId;

  const hasPharmacyCode = values?.pharmacyCode && values?.pharmacyCode !== "";
  const pharmacyCode = hasPharmacyCode ? values?.pharmacyCode : "WHP10817";
  const pharmacyValues = isPharmacy
    ? {
        pharmacyCode,
        pharmacyName: values?.pharmacyName,
        isDelivery: values?.isDelivery,
        pharmacyAddress: values?.pharmacyAddress,
      }
    : {};

  const followUpValues = isFollowUp
    ? {
        isFollowUp: values?.isFollowUp,
        followUpConsultationId: values?.followUpConsultationId,
        doctor: values?.doctor,
      }
    : {};

  const dependantValues = isDependant
    ? { principalHmoId: values?.principalHmoId }
    : {};

  const hospitalConsultationValues = isHospitalConsultation
    ? {
        time: values?.time,
        doctor: values?.doctor,
      }
    : {};

  const type = isHospitalConsultation ? "scheduled" : "instant";
  const formState = JSON.parse(sessionStorage.getItem("formState") || "{}");
  const hospitalProviderId = formState?.selectedHospital?._id;
  const providerId = isHospitalConsultation
    ? hospitalProviderId
    : values?.providerId;

  const payload = {
    type,
    consultationOwner: values?.consultationOwner,
    symptoms: values?.symptoms,
    discomfortLevel: values?.discomfortLevel,
    firstNotice: values?.firstNotice,
    providerId,
    contactMedium: values?.contactMedium,
    patientData: {
      email: values?.email,
      firstName: values?.firstName,
      lastName: values?.lastName,
      phoneNumber: values?.phoneNumber,
      gender: values?.gender?.toLowerCase(),
      hmoId: values?.hmoId,
      image: values?.image,
      dob: values?.dob,
    },
    timeOffset: "",
    createdThrough: values?.createdThrough || "weblink",
    ...pharmacyValues,
    ...followUpValues,
    ...dependantValues,
    ...hospitalConsultationValues,
  };

  if (values?.description) {
    payload.description = values.description;
  }
  if (values?.address) {
    payload.address = values.address;
  }

  return payload;
};

export const getClosestPharmacies = async (getPharmacies, address) => {
  try {
    const stateAndLgaRes = await getPharmacies({
      variables: { lga: address?.lga, state: address?.state },
    });
    const pharmacyArr =
      stateAndLgaRes?.data?.getPharmaciesFromWellaHealth?.data || [];

    if (pharmacyArr && pharmacyArr?.length > 0) {
      return pharmacyArr;
    }

    const stateRes = await getPharmacies({
      variables: { state: address?.state },
    });
    return stateRes?.data?.getPharmaciesFromWellaHealth?.data || [];
  } catch (error) {
    console.error("Error from getClosestPharmacies func:", error);
  }
};

export const updateUserInfo = async (values, userObj, UPDATE_USER_INFO) => {
  try {
    const hmoId = values?.hmoId;
    // UPDATE EMAIL
    if (values.email && values?.email !== userObj?.email)
      await UPDATE_USER_INFO({ email: values?.email, hmoId }, userObj?._id);
    // UPDATE PHONE NUMBER
    if (values?.phoneNumber && values?.phoneNumber !== userObj?.phone)
      await UPDATE_USER_INFO(
        { phone: values?.phoneNumber, hmoId },
        userObj?._id
      );
    // UPDATE DOB
    if (values?.dob && values?.dob !== userObj?.dob)
      await UPDATE_USER_INFO({ dob: values?.dob, hmoId }, userObj?._id);

    // UPDATE GENDER
    if (values?.gender && values?.gender !== userObj?.gender)
      await UPDATE_USER_INFO({ gender: values?.gender, hmoId }, userObj?._id);
  } catch (error) {
    console.error(error);
  }
};

export const updateManifest = async (url, manifest) => {
  fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(manifest),
  })
    .then(() => {})
    .catch((error) => {
      console.error("Error injecting manifest:", error);
    });
};

export const formulateAndUpdateManifest = async (
  subDomain = "Heala App",
  widgetLogo = "https://heala-partners-2qzf8yrm7-heala-io.vercel.app/static/media/logo.723375eb.svg"
) => {
  try {
    document.title = subDomain;
    const favicon = document.querySelector("link[rel='icon']");
    favicon.href = widgetLogo;

    const baseUrl = `${window.location.protocol}//${window.location.host}`;

    const res = await fetch(baseUrl + "/manifest.json");
    const oldManifest = await res.json();

    const newManifest = JSON.stringify({
      ...oldManifest,
      name: subDomain,
      short_name: subDomain,
      icons: [
        {
          src: widgetLogo,
          sizes: "64x64 32x32 24x24 16x16",
          type: "image/x-icon",
        },
        {
          src: widgetLogo,
          type: "image/png",
          sizes: "192x192",
        },
      ],
    });
    await updateManifest(baseUrl, newManifest);
  } catch (error) {
    console.error("Error fetching manifest:", error);
  }
};

export const addValuesToForm = (setValue, obj) => {
  try {
    const keysArr = Object.keys(obj);
    for (let index = 0; index < keysArr.length; index++) {
      const key = keysArr[index];
      if (setValue) setValue(key, obj[key]);
    }
  } catch (error) {
    console.error("Couldn't update form values", error);
  }
};

export const updateFormValues = (setValue, formState) => {
  try {
    if (!setValue) return;

    const data = formState?.userData || {};

    const {
      providerId,
      firstName,
      lastName,
      email,
      phone,
      photo,
      dob,
      gender,
    } = data;
    const obj = {
      providerId,
      firstName,
      lastName,
      email,
      dob,
      gender,
      phoneNumber: phone,
      image: photo ? photo : defaultProfilePics,
    };

    addValuesToForm(setValue, obj);
  } catch (error) {
    console.error(
      "Couldn't update form values from updateFormValues func:",
      error
    );
  }
};

export const updateFollowUpForm = (setValue, prevConsultation) => {
  try {
    if (!setValue) return;

    const { doctor, symptoms, _id, patientData, firstNotice, discomfortLevel } =
      prevConsultation || {};
    const gender = patientData?.gender.toLowerCase();
    const dateOfBirth = isDateValid(patientData?.dob)
      ? patientData?.dob
      : undefined;

    const dob = isValid(new Date(dateOfBirth))
      ? format(dateOfBirth, "yyyy-MM-dd")
      : "";
    const obj = {
      isFollowUp: true,
      followUpConsultationId: _id,
      doctor,
      symptoms,
      gender,
      firstNotice,
      discomfortLevel,
      dob,
    };
    addValuesToForm(setValue, obj);
  } catch (error) {
    console.error(
      "Couldn't update form values from updateFormValues func:",
      error
    );
  }
};

export const normalizeEmail = (email = "") => {
  try {
    if (!email) return email;
    const normalizedEmail = email.split("+").join("%2B");
    return normalizedEmail;
  } catch (error) {
    console.error(error);
    return email;
  }
};

export function isDateValid(dateStr) {
  return !isNaN(new Date(dateStr));
}

export const checkAndFormatAddress = (address) => {
  try {
    if (!address || address === "") return { ...defaultAddress };
    const addressArr = address.split(", ");
    const arrLength = addressArr?.length;
    const street = addressArr.slice(0, arrLength - 2).join(" ");
    const lga = addressArr[arrLength - 2];
    const stateWithDot = addressArr[arrLength - 1];
    const stateArr = stateWithDot.split("");
    const state =
      stateArr[stateArr.length - 1] === "."
        ? stateArr.slice(0, stateArr.length - 1).join("")
        : stateArr.join("");

    return {
      street,
      lga,
      state,
    };
  } catch (error) {
    console.error("Error from checkAndFormatAddress FN:", error);
    return { ...defaultAddress };
  }
};

export const removeDuplicateCommas = (inputString) => {
  // Use regular expression to replace multiple commas with a single comma
  const resultString = inputString.replace(/,+/g, ",");

  // If the string starts with a comma, remove it
  return resultString.startsWith(",") ? resultString.slice(1) : resultString;
};

export const convertToSentenceCase = (string) => {
  return `${string[0]}`.toUpperCase() + `${string}`.slice(1).toLowerCase();
};

export const isBelowLaptopSize = () => {
  if (!window) return;
  // Define the threshold for a laptop screen width
  const laptopScreenWidth = 1024;

  // Check if the current screen width is less than the laptop screen width threshold
  return window?.innerWidth < laptopScreenWidth;
};

export const getNextStepFromIntroForm = (
  isFollowUp,
  isHospitalConsultation
) => {
  return isFollowUp
    ? steps.SELECT_DOCTOR
    : isHospitalConsultation
    ? hospitalSteps.SELECT_HOSPITAL
    : steps.CREATE;
};

export const getIsHospitalConsultation = (pathname = "") => {
  const path = pathname?.split("/")[2];
  return path === "hospital-consultation";
};

export const getPrevStepInCreateConsultationForm = (
  isFollowUp,
  isHospitalConsultation
) => {
  if (isFollowUp) return hospitalSteps.SELECT_DOCTOR;
  if (isHospitalConsultation) return hospitalSteps.SELECT_HOSPITAL_DOCTOR;
  return steps.INTRO_FORM;
};

export const formatAvailableTimeSlot = (slots, serverLagosTime) => {
  try {
    const hour = serverLagosTime.split(" ")[0];

    const oneHourAheadUtc = Number(hour) + 1;

    const filteredSlots = slots?.filter((slot) => {
      let hourSlot = slot.start?.split("").splice(0, 2).join("");
      let available = slot.available;

      return (
        Number(hourSlot) > Number(hour) &&
        Number(hourSlot) > Number(oneHourAheadUtc) &&
        available == true
      );
    });

    return filteredSlots;
  } catch (error) {
    console.error("Error from  formatAvailableTimeSlot FN:", error);
    return [];
  }
};

export const hideFollowUpInput = (isFollowUp, hasError) => {
  try {
    return isFollowUp && !hasError;
  } catch (error) {
    return false;
  }
};

export const isObject = (value) => {
  return value !== null && typeof value === "object" && !Array.isArray(value);
};

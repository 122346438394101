import React, { useMemo, useState } from "react";
import { Add } from "@mui/icons-material";
import { useFormContext } from "react-hook-form";
import { InputContainer } from "../../InputContainer";
import { AddressModal } from "../../modals/AddressModal";
import { useAppState } from "../../../contexts/appContext";
import {
  checkAddress,
  checkAndFormatAddress,
  removeDuplicateCommas,
} from "../../../utils/funcs";

export const AddressInput = () => {
  const appState = useAppState();
  const { widgetColor } = appState?.partnerInfo || {};
  const [addressModal, setAddressModal] = useState(false);
  const {
    getValues,
    setValue,
    register,
    formState: { errors },
  } = useFormContext();

  const address = checkAndFormatAddress(getValues()?.address || "");

  const isAddressComplete = useMemo(() => {
    return checkAddress(address);
  }, [address]);

  return (
    <>
      <InputContainer
        label={`Address ${!isAddressComplete ? "(click to add address)" : ""}`}
        // info="This address will be used to deliver any prescriptions (drugs) from the doctor."
        error={errors["address"]?.message}
        required
      >
        <div className="space-x-2">
          {isAddressComplete && (
            <div className="relative">
              <input
                type="text"
                style={{ outlineColor: widgetColor }}
                {...register("address")}
                disabled
              />
              <p
                className="h-4 w-4 flex justify-center bg-red-100 !font-bold !text-[10px] !text-red-500 border border-red-500 rounded-full absolute -top-1 -right-1 cursor-pointer"
                onClick={() => {
                  //setValue("address", undefined);
                  setValue("address", undefined, {
                    shouldValidate: true,
                  });
                  setValue("pharmacyCode", undefined);
                }}
              >
                X
              </p>
            </div>
          )}
          {!isAddressComplete && (
            <button
              style={{
                color: widgetColor,
                border: `1px solid ${widgetColor}`,
              }}
              className="!bg-transparent flex justify-center items-center space-x-2"
              onClick={(e) => {
                e.preventDefault();
                setAddressModal(true);
              }}
            >
              <Add size="20" sx={{ color: widgetColor }} /> Add Address
            </button>
          )}
        </div>
      </InputContainer>
      <AddressModal
        open={addressModal}
        setOpen={() => setAddressModal(false)}
        brandColor={widgetColor}
        addAddress={(addressObj) => {
          const address = `${addressObj?.street}, ${addressObj?.lga}, ${addressObj?.state}.`;
          setValue("address", removeDuplicateCommas(address));
        }}
      />
    </>
  );
};

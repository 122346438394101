import React, { useState } from "react";
import { Chip } from "@mui/material";
import { useFormContext } from "react-hook-form";
import { InputContainer } from "../../InputContainer";
import { useAppState } from "../../../contexts/appContext";
import {
  convertToSentenceCase /* isBelowLaptopSize */,
} from "../../../utils/funcs";

// const isMobile = isBelowLaptopSize();
// const infoTextForPCs = "Click enter to input multiple symptoms";
// const infoTextForMobile = "Separate symptoms with a comma (,)";
// const infoText = "Click enter/return to input multiple symptoms or separate symptoms with a comma (,)";

export const Symptoms = ({ issues }) => {
  const appState = useAppState();
  const [symptom, setSymptom] = useState("");
  const { widgetColor } = appState?.partnerInfo || {};
  const [filteredIssues, setFilteredIssues] = useState([]);

  const {
    setValue,
    getValues,
    formState: { errors },
  } = useFormContext();

  const addSymptom = (symptom) => {
    if (symptom === "" || symptom === " ") {
      return;
    }

    const trimmedSymptom = symptom.trim();
    const symptomArr = trimmedSymptom.split(/[,]+/);

    for (let index = 0; index < symptomArr.length; index++) {
      const symptom = convertToSentenceCase(`${symptomArr[index]}`);
      setFilteredIssues([]);
      setSymptom("");
      const prevSymptoms = getValues("symptoms") ? getValues("symptoms") : [];
      setValue("symptoms", [{ name: symptom }, ...prevSymptoms]);
    }
  };

  // Handle typing in symptoms input
  const handleSymptomChange = (event) => {
    const value = event.target.value;

    if (value === "" || value === " ") {
      setSymptom("");
      setFilteredIssues([]);
      return;
    }

    const symptomStringCase = convertToSentenceCase(value);

    if (value[value.length - 1] === ",") {
      const valueWithoutComma = `${value}`.replace(/,/g, "");
      addSymptom(valueWithoutComma);
      return;
    }

    setSymptom(symptomStringCase);

    const filtered = issues.filter((issue) =>
      issue.Name.includes(symptomStringCase)
    );
    setFilteredIssues(filtered);
  };

  const removeSymptom = (id) => {
    const prevSymptoms = getValues("symptoms") ? getValues("symptoms") : [];
    const filteredSymptoms = (prevSymptoms || []).filter(
      (_, idx) => idx !== id
    );
    setValue("symptoms", filteredSymptoms);
  };

  return (
    <InputContainer
      label="What are your symptoms?"
      info="Click Enter or Add to input multiple symptoms or separate with commas (,)."
      error={errors["symptoms"]?.message}
      required
    >
      <div>
        <div className="heela-form-symptoms">
          <div className="heela-form-top">
            <div className="heela-symptoms-input">
              <input
                type="text"
                name="name"
                autoComplete="off"
                id="heela-symptoms"
                placeholder="Enter Symptoms"
                value={symptom}
                onChange={handleSymptomChange}
                style={{
                  width: "100%",
                  marginTop: "3px",
                  outlineColor: widgetColor,
                }}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    e.stopPropagation();
                    e.preventDefault();
                  }
                }}
                onKeyUp={(e) => {
                  const isEmpty = !symptom || symptom === "";
                  if (e.key === "Enter") {
                    if (isEmpty) return;
                    setSymptom(symptom);
                    setFilteredIssues([]);
                    addSymptom(e.target.value);
                  }
                }}
              />
              <p
                onClick={() => addSymptom(symptom)}
                style={{ backgroundColor: widgetColor }}
                className="!text-white font-medium !ml-2 flex items-center justify-center !px-2 !py-1 !mt-[3px] rounded-md cursor-pointer"
              >
                Add
              </p>
            </div>
          </div>
          {filteredIssues.length > 0 && (
            <div
              className="heela-symptoms-container"
              style={{ padding: "1rem" }}
            >
              {filteredIssues.map((item) => (
                <div
                  key={item.ID}
                  className="heela-symptoms-option"
                  onClick={() => addSymptom(item?.Name)}
                >
                  {item.Name}
                </div>
              ))}
            </div>
          )}
        </div>
        <div className="heela-symptoms-batches-container flex flex-wrap max-w-[450px]">
          {(getValues("symptoms") || []).map((symptom, idx) => (
            <Chip
              label={symptom?.name}
              onDelete={() => {
                removeSymptom(idx);
              }}
              key={idx}
            />
          ))}
        </div>
      </div>
    </InputContainer>
  );
};
